// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/img/arrow_left.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/static/img/andromeda.png", import.meta.url);
var ___HTML_LOADER_IMPORT_2___ = new URL("/static/img/checkfill.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_3___ = new URL("/static/img/logo_white.png", import.meta.url);
var ___HTML_LOADER_IMPORT_4___ = new URL("/static/img/copy.svg", import.meta.url);
// Module
var code = `<template>
  <require from="./success.scss"></require>
  <require from="../../../../components/animated_checkmark/animated_checkmark"></require>
  <require from="../../../../components/max-length"></require>
  <section id="success" class="column">
    <div id="header" class="flex-row">
      <img id="thumbnail" click.delegate="goBack()" src="${___HTML_LOADER_IMPORT_0___}">
      <h1>Success!</h1>
    </div>
    <animated-checkmark></animated-checkmark>
    <div class="img_info flex-row">
      <img class="nft" src="${___HTML_LOADER_IMPORT_1___}">
      <div class="info flex-column">
        <div class="author_check flex-row">
          <a class="author" href="https://www.youtube.com/watch?v=dQw4w9WgXcQ">Creator</a>
          <img class="check" src="${___HTML_LOADER_IMPORT_2___}">
        </div>
        <p class="nft_name">Nft's name/collection</p>
        <p class="nft_number gray">#1</p>
        <p class="bsv_price">₿ 0.38095</p>
      </div>
    </div>
    <h5 class="date gray">Nov 31 2023 18:55</h5>
    <form class="inputs">
      <div>
        <input value.bind="commentary" type="text" placeholder="Merry Christmas & Happy New Year!" readonly>
      </div>
    </form>
    <div class="payment_info_container flex-column">
      <p class="info_title gray">Paid to</p>
      <div class="receiver_info flex-row">
        <img class="profilePic" src="${___HTML_LOADER_IMPORT_3___}">
        <div class="name_paymail flex-column">
          <p class="name">Ben</p>
          <p class="paymail gray">Ben@mybanka.eu</p>
        </div>
      </div>
      <p class="info_title gray">Debited from</p>
      <div class="debiter_info flex-row">
        <img class="profilePic" src="${___HTML_LOADER_IMPORT_3___}">
        <div class="name_paymail flex-column">
          <p class="name">Ben</p>
          <p class="paymail gray">Ben2@mybanka.eu</p>
        </div>
      </div>
      <p class="info_title gray">Transaction ID</p>
      <div class="id_copy flex-row">
        <a class="transaction_id">u0hi9hygf1654tgijohgègg16pihi</a>
        <div class="flex-grow"></div>
        <img class="copy" src="${___HTML_LOADER_IMPORT_4___}">
      </div>
    </div>
  </section>
</template>

`;
// Exports
export default code;