// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/img/arrow_left.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/static/img/andromeda.png", import.meta.url);
var ___HTML_LOADER_IMPORT_2___ = new URL("/static/img/checkfill.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_3___ = new URL("/static/img/currency/bitcoin/bsvfull.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_4___ = new URL("/static/img/user-circle.svg", import.meta.url);
// Module
var code = `<template>
  <require from="./payment.scss"></require>
  <require from="../../../components/ValueConverters/currency-denomination"></require>  <require from="../../../components/number-format"></require>
  <require from="../../../components/ValueConverters/iban-converter"></require>
  <section id="payment" class="flex-column">
    <div id="header" class="flex-row">
      <img id="thumbnail" click.delegate="goBack()" src="${___HTML_LOADER_IMPORT_0___}">
      <h1>Payment</h1>
    </div>
    <div class="img_info flex-row">
      <img class="nft" src="${___HTML_LOADER_IMPORT_1___}">
      <div class="info flex-column">
        <div class="author_check flex-row">
          <a class="author" href="https://www.youtube.com/watch?v=dQw4w9WgXcQ">Creator</a>
          <img class="check" src="${___HTML_LOADER_IMPORT_2___}">
        </div>
        <p class="nft_name">Nft's name/collection</p>
        <p class="nft_number gray">#1</p>
        <p class="bsv_price">₿ 0.38095</p>
      </div>
    </div>
    <p class="payment_title gray">Paying from</p>
    <div class="listing">
      <div class="object flex-row" click.delegate="goToChooseAccount()">
        <img if.bind="bank" src.bind="banks[currentBankIndex].institutionDetails.media[0].source">
        <img if.bind="wallet" src="${___HTML_LOADER_IMPORT_3___}" />
        <div class="texte flex-column">
          <p class="title" if.bind="bank">\${banks[currentBankIndex].accounts[currentAccountIndex].accountNames[0].name || banks[currentBankIndex].accounts[currentAccountIndex].type}</p>
          <p class="desc" if.bind="bank">\${banks[currentBankIndex].accounts[currentAccountIndex].accountIdentifications[0].identification | ibanConverter}</p>
          <p class="title" if.bind="wallet">\${ walletInfo.name }</p>
          <p class="desc" if.bind="wallet">
            ₿\${ ((walletInfo.balance) ? (walletInfo.balance / 100000000) :
            '0') | numberFormat:'0.00000000'}
          </p>
        </div>
        <div class="flex-grow"></div>
        <p class="solde">\${banks[currentBankIndex].accounts[currentAccountIndex].currency | currencyDenomination}\${banks[currentBankIndex].accounts[currentAccountIndex].balance | numberFormat:'0,0.00'}</p>
      </div>
    </div>
    <p class="payment_title gray">To</p>
    <div class="listing">
      <div class="object flex-row" click.delegate="goToContactsSelectBitcoin()">
        <img src="${___HTML_LOADER_IMPORT_4___}" />
        <div class="texte flex-column">
          <p class="title"><span t="bitcoin.payment.make_payment_select_recipient_bitcoin"></span></p>
          <p class="desc"></p>
        </div>
        <div class="flex-grow"></div>
      </div>
    </div>
    <div class="flex-grow"></div>
    <button class="btn btn-primary">
      <span>Next</span>
    </button>
    <div class="spacer-default"></div>
  </section>
</template>

`;
// Exports
export default code;