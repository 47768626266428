// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/img/arrow_left.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/static/img/nft_sample_art.jpg", import.meta.url);
var ___HTML_LOADER_IMPORT_2___ = new URL("/static/svg/heart_icon.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_3___ = new URL("/static/svg/eye_show.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_4___ = new URL("/static/svg/small_menu_Icon.svg", import.meta.url);
// Module
var code = `<template>
  <require from="./nft_details.scss"></require>
  <section id="nft_details_page" class="flex-column">
    <div id="header" class="flex-row">
      <img
        id="thumbnail"
        click.delegate="goBack()"
        src="${___HTML_LOADER_IMPORT_0___}"
      />
      <div class="flex-grow"></div>
      <!-- <p>GIFT</p> -->
    </div>

    <div class="nft">
      <div class="nft_image">
        <img src="${___HTML_LOADER_IMPORT_1___}" />
        <div class="nft_social flex-row">
          <div class="heart flex-row">
            <img src="${___HTML_LOADER_IMPORT_2___}" alt="" />
            <p>68</p>
          </div>
          <div class="views flex-row">
            <img src="${___HTML_LOADER_IMPORT_3___}" alt="" />
            <p>124.6k</p>
          </div>
          <div class="menu flex-row">
            <img src="${___HTML_LOADER_IMPORT_4___}" alt="" />
          </div>
        </div>
      </div>

      <div class="nft_deatils flex-column">
        <div class="nft_social flex-row">
          <div class="heart flex-row">
            <img src="${___HTML_LOADER_IMPORT_2___}" alt="" />
            <p>68</p>
          </div>
          <div class="views flex-row">
            <img src="${___HTML_LOADER_IMPORT_3___}" alt="" />
            <p>124.6k</p>
          </div>
          <div class="menu flex-row">
            <img src="${___HTML_LOADER_IMPORT_4___}" alt="" />
          </div>
        </div>

        <div class="nft_main flex-column">
          <div class="nft_name"><h3>HypeFellaz #1428</h3></div>
          <div class="nft_description">
            <p>
              A collection of 10,000 undead NFTs minted on the Ethereum
              blockchain. Each unique Deadfella is randomly generated from a
              combination of over 400 individually,
            </p>
          </div>
        </div>

        <div class="nft_price flex-row">
          <div class="auction_price flex-column">
            <h4>₿ 0.38095</h4>
            <p>Auction Price</p>
          </div>
          <div class="buy_price flex-column">
            <h4>\$ 0.38095</h4>
            <p>Buy it now</p>
          </div>
        </div>
      </div>
    </div>

    <div class="flex-grow"></div>
    <button class="btn btn-primary">
      <span>Buy</span>
    </button>
    <div class="spacer-default"></div>
  </section>
</template>
`;
// Exports
export default code;