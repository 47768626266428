// Module
var code = `<template>
  <require from="./edit.scss"></require>
  <section id="edit" class="flex-column">
    <div class="drop_box flex-column">
      <p>Prévisualisation</p>
    </div>
    <form id="nameForm" class="inputs">
      <div>
        <input placeholder="Name" ref="name" id="name">
      </div>
    </form>
    <div class="flex-grow"></div>
    <div class="spacer-default"></div>
    <button class="btn btn-primary">Save changes</button>	
    <button class="btn btn-primary">Send</button>	
    <button class="btn btn-secondary">Delete</button>	
  </section>
</template>
`;
// Exports
export default code;