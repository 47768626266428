import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';
import { FileUploadHttpClients } from "http_clients/FileUploadHttpClients";

@autoinject
export class edit {
  private isVisualOn: boolean = false;

    constructor(private router: Router, private fileUploadHttpClients: FileUploadHttpClients,) {

    }
}
