// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/img/arrow_left.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/static/img/andromeda.png", import.meta.url);
var ___HTML_LOADER_IMPORT_2___ = new URL("/static/img/checkfill.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_3___ = new URL("/static/img/user-circle.svg", import.meta.url);
// Module
var code = `<template>
  <require from="./recap.scss"></require>
  <section id="recap" class="flex-column">
    <div id="header" class="flex-row">
      <img id="thumbnail" click.delegate="goBack()" src="${___HTML_LOADER_IMPORT_0___}">
      <div class="flex-grow"></div>
    </div>
    <div class="img_info flex-row">
      <img class="nft" src="${___HTML_LOADER_IMPORT_1___}">
      <div class="info flex-column">
        <div class="author_check flex-row">
          <a class="author" href="https://www.youtube.com/watch?v=dQw4w9WgXcQ">Creator</a>
          <img class="check" src="${___HTML_LOADER_IMPORT_2___}">
        </div>
        <p class="nft_name">Nft's name/collection</p>
        <p class="nft_number gray">#1</p>
        <p class="bsv_price">₿ 0.38095</p>
      </div>
    </div>
    <form class="inputs" id="commentaryform">
      <p class="input_title gray">Note</p>
      <div>
        <input value.bind="commentary" placeholder="Merry Christmas & Happy New Year!" type="text" readonly>
      </div>
    </form>
    <div class="receiver_container flex-column">
      <p class="input_title gray">Send to</p>
      <div class="listing">
        <div class="object flex-row" click.delegate="goToContactsSelectBitcoin()">
          <img src="${___HTML_LOADER_IMPORT_3___}" />
          <div class="texte flex-column">
            <p class="title"><span t="bitcoin.payment.make_payment_select_recipient_bitcoin"></span></p>
            <p class="desc"></p>
          </div>
          <div class="flex-grow"></div>
        </div>
      </div>
    </div>
    <div class="flex-grow"></div>
    <button class="btn btn-primary">
      <span>Pay Now</span>
    </button>
    <div class="spacer-default"></div>
  </section>
</template>

`;
// Exports
export default code;